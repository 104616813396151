<template>
  <div class="PERInformation">
    <div class="PERInformation-head">
      <content-title :nav="nav"></content-title>

      <el-form :inline="true">
        <el-form-item label="类型">
          <el-select v-model="type" placeholder="请选择" clearable>
            <el-option
              v-for="item in opction"
              :key="item.value"
              :label="item.value"
              :value="item.field"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <el-button type="warning" @click="addTitle = true"
              >新增类型</el-button
            >
            <el-button type="success" @click="editAdd(0)">新增广告</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="PERInformation-table">
      <div class="tables">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="dataBanner"
          ref="multipleTable"
          @row-click="getDetails"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
          v-loading="is_loading"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column align="center" label="Banner" width="240px">
            <template slot-scope="{ row }">
              <div>
                <el-image
                  :src="row.pic"
                  class="table-img"
                  :preview-src-list="[row.pic]"
                >
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="city"
            align="center"
            label="城市"
          ></el-table-column>
          <el-table-column
            prop="banner_name"
            align="center"
            label="Banner名称"
          ></el-table-column>
          <el-table-column
            prop="start_time"
            align="center"
            label="开始时间"
          ></el-table-column>
          <el-table-column
            prop="end_time"
            align="center"
            label="截止时间"
          ></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.state == 1" style="color: #23c3c4">正在使用</span>
              <span v-else-if="row.state == 2" style="color: #b8b8b8"
                >未使用</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="is_recommend" align="left" label="操作">
            <template slot-scope="scope">
              <el-button
                :type="state[scope.$index] == '下架' ? 'info' : 'primary'"
                @click="flagOffS(scope.$index)"
                size="small"
                >{{ state[scope.$index] }}
              </el-button>
              <el-button type="warning" @click="editAdd(1)" size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <!-- <el-pagination background @current-change="Tonext" @size-change="handleSizeChange"
          :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="total">
        </el-pagination> -->
        <paging-fy
          @currentPageChange="Tonext"
          :currentPage="currentPage3"
          :total="total"
        ></paging-fy>
      </div>
    </div>

    <!-- 添加广告标题弹窗 -->
    <div v-if="addTitle">
      <div class="login">
        <div class="popupcontents">
          <div class="add_title">
            <p>添加广告位置</p>
            <div class="add_title_form">
              <label>标题名称</label>
              <el-input
                class="add_title_input"
                type="text"
                v-model="adTitle"
                placeholder="请输入广告标题"
              ></el-input>
            </div>
            <div class="add_title_form">
              <label>标题类型</label>
              <el-input
                class="add_title_input"
                type="text"
                v-model="adType"
                placeholder="请输入广告类型"
              ></el-input>
            </div>
            <div class="add_title_form">
              <label>描述</label>
              <el-input
                class="add_title_input"
                style="left: 42px"
                :rows="2"
                type="textarea"
                v-model="adDetails"
                placeholder="请输入广告介绍"
              ></el-input>
            </div>
            <div class="add_title_btn">
              <el-button type="primary" @click="addTitleTo()"
                >立即创建</el-button
              >
              <el-button @click="toclose">取消</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="over" @click="toclose"></div>
    </div>
    <!-- 下架弹框 -->
    <div v-if="flagOff">
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="150px">
            <span class="deleteAd">确认{{ Shelf }}该广告？</span>
            <div class="btns">
              <el-button @click="toclose">取消</el-button>
              <el-button type="primary" @click="offShelf()">确认</el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div class="over" @click="toclose"></div>
    </div>
    <!-- banner新增编辑弹框 -->
    <div v-if="Asshow">
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" :data="sels" label-width="100px">
            <span class="formTitle">{{ editAddNum ? "编辑" : "新增" }}</span>
            <el-form-item label="banner" class="image">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccess"
                :clear="true"
                :url="dialogImageUrl"
              ></avatar-uploader>
              <!-- <el-upload class="avatar-uploader" :action="domain" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeUploadGetKey" :data="QiNiYun">
                <img v-if="dialogImageUrl" @click="getQiniuToken()" :src="dialogImageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
            </el-form-item>

            <el-form-item label="活动id">
              <el-input type="text" v-model="sels.activity_id"></el-input>
            </el-form-item>

            <el-form-item label="Banner名称">
              <el-input type="text" v-model="sels.banner_name"></el-input>
            </el-form-item>
            <el-form-item label="Banner位置">
              <el-select v-model="sels.type" placeholder="请选择">
                <el-option
                  v-for="item in opction"
                  :key="item.value"
                  :label="item.value"
                  :value="item.field"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 判断网页还是按钮 -->
            <!-- <el-form-item label="来源">
              <el-select style="width: 100%" v-model="sels.source_type" placeholder="请选择">
                <el-option v-for="(item,indexs) in source" @blur="sourceIf()" :key="indexs" :value="item.type" :label="item.title"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="跳转链接">
              <el-input
                v-model="sels.source_url"
                type="text"
                placeholder="请输入网址"
              ></el-input>
            </el-form-item>
            <el-form-item label="城市">
              <el-radio v-model="allcity" :label="1">全部城市</el-radio>
              <el-radio v-model="allcity" :label="2">选择城市</el-radio>
              <el-cascader
                v-if="allcity == 2"
                v-model="cityList"
                :options="cityoptions"
                :props="cityProps"
                :placeholder="sels.city"
                filterable
              ></el-cascader>
            </el-form-item>
            <el-form-item
              v-if="sels.source_type == 'master'"
              label="助学师按钮"
            >
              <el-button
                type="primary"
                @click="selecthelpteacher((Selhelp = true))"
                >选择助学师</el-button
              >
              <el-input
                placeholder="选择的助学师的usrid"
                v-model="use_teacherid"
                :disabled="true"
              >
              </el-input>
              <!-- 助学师名字 -->
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker
                v-model="sels.start_time"
                default-time="12:00:00"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="sels.end_time"
                default-time="12:00:00"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <template>
                <el-button type="primary" @click="addToAd()">{{
                  editAddNum ? "修改" : "添加"
                }}</el-button>
                <el-button @click="toclose">取消</el-button>
              </template>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="over" @click="toclose"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MobileAd",
  data() {
    return {
      nav: {
        firstNav: "运营管理中心",
        secondNav: "广告位管理",
      },
      is_loading: true,
      seletfull_name: "",
      tableData: [],
      cellstya: {},
      Selhelp: false,
      source: [
        { title: "学习卡", type: "learningcard" },
        { title: "会员", type: "member" },
        // {title:'充值猫币',type:"recharge"},
        { title: "地球圈", type: "note" },
        { title: "好奇", type: "news" },
        { title: "用户", type: "user" },
        { title: "页面", type: "web" },
        { title: "聊天", type: "message" },
        { title: "助学师", type: "master" },
        { title: "机构", type: "mechanism" },
      ],
      opction: [], //广告头数据
      dataBanner: [], //数据列表
      inputURL: "",
      allcity: 1,
      sels: {
        activity_id: "",
        banner_name: "",
        type: "",
        source_url: "",
        source_type: "",
        create_time: "",
        update_time: "",
      }, //当前列数据
      state: [], //数据状态
      navsindex: 0, //navs索引
      currentPage: 1, //页数
      currentPage3: 1,
      currentPageteacher: 1,
      Asshow: false, //判断 编辑或新增 弹框
      editAddNum: 0, //编辑为1，新增为0
      flagOff: false, //是否弹出   下架弹框
      addTitle: false, //添加title
      Shelf: "",

      nameBanner: "", //新增的名称
      positionBanner: "", //新增的位置
      start_time: "", //新增的开始时间
      end_time: "", //新增的结束时间
      use_teacherid: "", //助学师id
      adTitle: "",
      adType: "",
      adDetails: "",
      types: "mine_hot",
      tableDatateacher: [],
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      dialogImageUrl: "",
      QiNiYun: {
        key: "",
        token: "",
      },
      total: 0, //总条数
      teachertotal: 0, //助教总条数
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      type: null,
    };
  },
  mounted() {
    let citys = JSON.parse(localStorage.getItem("addrList"));
    citys.forEach((item) => {
      if (item.childrens) {
        item.childrens.forEach((c) => {
          delete c.childrens;
        });
      }
    });
    this.cityoptions = citys;

    this.getAd();
    this.GetListAd();
    this.GetListteacher();
  },
  methods: {
    search() {
      this.GetListAd();
    },
    // 新增广告类型
    getAd() {
      this.$axios({
        url: "/user/bannerType/insert",
        params: this.bannerType,
        method: "post",
      }).then((res) => {
        // console.log(res.data);
        res.data.data.rows.forEach((e) => {
          this.opction.push({ value: e.title, field: e.type });
        });
        this.types = this.opction[0].field;
        // console.log(this.opction,"广告头")
      });
    },
    // 查询助教师表格
    toquery() {
      this.$axios
        .get(
          "/user/userInfo/queryByMessage?pageSize=10&currentPage=" +
            this.currentPageteacher +
            "&full_name=" +
            this.seletfull_name
        )
        .then((res) => {
          // console.log(res.data, "===>助学师列表");
          this.tableDatateacher = res.data.data.rows;
          this.teachertotal = res.data.data.total;
        });
    },
    handleCurrentChange(val) {
      this.currentPageteacher = val;
      // console.log(val);
      this.GetListteacher();
      // console.log(this.tableDatateacher,"232");
    },
    // 选择助学师按钮
    handleClick(a) {
      this.use_teacherid = a.user_id;
    },
    // 新加广告标题
    addTitleTo() {
      if (this.adDetails == "" && this.adType == "" && this.adTitle == "") {
        this.$message({ type: "info", message: "请完整广告信息" });
        return;
      }
      let data = {
        title: this.adTitle,
        type: this.adType,
        banner_descr: this.adDetails,
      };
      data.city = this.cityList[1] || "";
      this.$axios.post("/user/bannerType/insert", data).then((res) => {
        this.addTitle = false;
        this.adTitle = "";
        this.adType = "";
        this.adDetails = "";
        this.getAd();
        // console.log(res.data.message);
      });
    },
    flagOffS(index) {
      this.flagOff = true;
      this.Shelf = this.state[index];
    },
    handleAvatarSuccess(file) {
      this.dialogImageUrl = file;
      // console.log(this.dialogImageUrl, "0000");
    },
    beforeUploadGetKey() {
      this.getQiniuToken();
    },
    getQiniuToken() {
      var data = {
        key: this.guid(),
        type: "pic",
      };
      this.$axios.post("/push/upload/getToken", data).then((res) => {
        // console.log(res.data.message);
        this.QiNiYun.token = res.data.data;
        this.QiNiYun.key = data.key;
      });
    },
    formatState(row, column, cellValue, index) {
      return row.state == 1 ? "已上架" : row.state == 2 ? "已下架" : "已过期";
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    Tonext(val) {
      this.currentPage = val;
      this.GetListAd();
    },
    // 获得广告头
    getAd() {
      this.opction = [];
      this.$axios
        .get("/user/bannerType/queryManagerListPage?status=2")
        .then((res) => {
          // console.log(res.data);
          res.data.data.rows.forEach((e) => {
            this.opction.push({ value: e.title, field: e.type });
          });
          this.types = this.opction[0].field;
          // console.log(this.opction,"广告头")
        });
    },
    addToAd() {
      //添加广告
      var _this = this;
      if (_this.editAddNum) {
        // console.log("修改", _this.sels);

        let forms = {
          id: this.sels.id,
          type: this.sels.type,
          pic: this.dialogImageUrl,
          source_url: this.sels.source_url,
          banner_name: this.sels.banner_name,
          banner_position: "",
          start_time: this.sels.start_time,
          end_time: this.sels.end_time,
          activity_id: this.sels.activity_id,
        };
        if (this.cityList.length >= 2) {
          forms.city = this.cityList[1];
        }
        if (this.allcity == 1) {
          forms.city = "";
        }
        this.$axios.post("/user/exhibitionPic/update", forms).then((res) => {
          this.getList();
          this.toclose();
          // alert(1)
        });
      } else {
        // console.log(this.sels,"添加");
        this.opction.forEach((e) => {
          if (e.value == this.sels.type) {
            this.sels.type = e.field;
          }
        });
        let form = {
          type: this.sels.type,
          pic: this.dialogImageUrl,
          source_url: this.sels.source_url,
          source_type: this.sels.source_type,
          state: 2,
          type_id: "",
          theme: "en",
          banner_name: this.sels.banner_name,
          banner_position: "",
          start_time: this.sels.start_time,
          end_time: this.sels.end_time,
          activity_id: this.sels.activity_id,
        };
        // console.log("新增信息", form);
        this.$axios.post("/user/exhibitionPic/insert", form).then((res) => {
          // console.log(res, form, "传参");
          this.toclose();
          this.getList();
        });
      }
    },
    GetListteacher() {
      this.$axios
        .get(
          "/user/userInfo/queryHelperInfoListPage?pageSize=10&currentPage=" +
            this.currentPageteacher +
            "&is_helper=true"
        )
        .then((res) => {
          // console.log(res.data, "===>助学师列表");
          this.tableDatateacher = res.data.data.rows;
          this.teachertotal = res.data.data.total;
        });
    },
    getDetails(row) {
      this.sels = row;
      this.allcity = row.city ? 2 : 1;
      this.dialogImageUrl = row.pic;
      // console.log(row, "当列数据");
    },
    editAdd(num) {
      //弹出编辑弹框
      if (!num) {
        this.sels = {
          activity_id: "",
          banner_name: "",
          type: "",
          source_url: "",
          source_type: "",
          create_time: "",
          update_time: "",
        }; //点击 新增 清空当前列表数据
        this.img = "";
        this.cityList = [];
        // console.log("新增")
      } else {
      }
      this.Asshow = true;
      this.editAddNum = num;

      // console.log(this.sels)
    },
    TOnav(index, typess) {
      //判断索引
      this.types = typess;
      this.navsindex = index;
      // console.log(this.types,this.navsindex,"type,索引")
      this.getList();
    },
    toclose() {
      //关闭弹窗
      this.Asshow = false;
      this.flagOff = false;
      this.addTitle = false;
      this.Selhelp = false;
    },
    getList() {
      this.state = [];
      this.$axios
        .get(
          "/user/exhibitionPic/queryManagerListPage?currentPage=" +
            this.currentPage +
            "&pageSize=10&status=2"
        )
        .then((res) => {
          // console.log(res.data.data);
          let index = 0;
          this.dataBanner = res.data.data.rows;
          this.total = res.data.data.total;
          res.data.data.rows.forEach((e) => {
            if (e.state == 1) {
              this.state.push("下架");
            } else if (e.state == 2) {
              this.state.push("上架");
            }
          });
        });
    },
    GetListAd() {
      //获取列表数据
      this.$axios({
        url: "/user/exhibitionPic/queryManagerListPage",
        params: {
          currentPage: this.currentPage,
          pageSize: 10,
          type: this.type || null,
        },
      }).then((res) => {
        // console.log(res.data.data, "课程列表");
        let index = 0;
        this.is_loading = false;
        this.dataBanner = res.data.data.rows;
        this.total = res.data.data.total;
        res.data.data.rows.forEach((e) => {
          if (e.state == 1) {
            this.state.push("下架");
          } else if (e.state == 2) {
            this.state.push("上架");
          }
        });
      });
    },
    offShelf() {
      // console.log("下架商品");
      let stateId;
      if (this.sels.state == 1) {
        stateId = 2;
      } else {
        stateId = 1;
      }
      let vals = {
        id: this.sels.id,
        state: stateId,
      };
      this.$axios.post("/user/exhibitionPic/update", vals).then((res) => {
        // console.log(res, "下架广告");
        let trpe = "";
        if (this.navsindex == 0) {
          trpe = "life_live_banner";
        } else if (this.navsindex == 1) {
          trpe = "live_banner";
        } else if (this.navsindex == 2) {
          trpe = "mechanism_hot";
        } else if (this.navsindex == 3) {
          trpe = "look_banner";
        } else if (this.navsindex == 4) {
          trpe = "master_title";
        } else if (this.navsindex == 5) {
          trpe = "advertisement";
        }
        this.getList(trpe);
        this.toclose();
      });
    },
  },
};
</script>
<style scoped>
.add_title p {
  width: 100%;
  text-align: center;
  font-size: 32px;
}
.add_title_form {
  margin: 16px 0;
}
.add_title_form label {
  font-size: 22px;
}
.add_title_input {
  width: 250px;
  margin-left: 20px;
}
.add_title_btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 24px 0;
}
.PERInformation {
  position: relative;
  width: 100%;
  background: #ffffff;
  min-height: 1200px;
}
.PERInformation-head {
  position: relative;
  width: calc(100% -40px);
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 20px 20px 0px 20px;
}
.PERInformation-head
  > .el-breadcrumb
  > .el-breadcrumb__item
  >>> .el-breadcrumb__inner {
  font-size: 20px;
  color: #434343;
  /* font-weight: bold; */
}
.PERInformation-head
  > .el-breadcrumb
  > .el-breadcrumb__item
  >>> .el-breadcrumb__separator {
  color: #47cecf;
  width: 11px;
  color: 15px;
}
.PERInformation-head > .navs {
  margin-left: auto;
}
.navs > ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.navs > ul > li {
  margin-left: 40px;
  cursor: pointer;
  color: #434343;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  /* font-weight: bold; */
}
.navsed {
  color: #32c1c2 !important;
  border-bottom: 3px solid #32c1c2;
}
/* 新增按钮 */
/* .top-up {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
} */
.top-up > span {
  display: inline-block;
  width: 74px;
  height: 32px;
  background: rgba(255, 65, 65, 1);
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

/* 弹框 */
.formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-left: 140px;
}
.login {
  position: fixed;
  font-size: 24px;
  /* height: 800px; */
  width: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}
.openhelp {
  overflow: hidden;
  overflow-y: auto;
  padding: 130px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2500;
}
.openhelp h2 {
  color: #32c1c2;
  padding-left: 20px;
}
.downhelp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: 2450;
  opacity: 0.7;
}
.popupcontents {
  position: relative;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: 1000;
  opacity: 0.7;
}

.offSheIf {
  margin-left: 10px;
}
.listEdit {
  margin: 10px 0;
  margin-left: 10px;
}

.deleteAd {
  display: block;
  font-weight: bold;
  color: #414141;
  text-align: center;
  margin: 15px 0;
}

.formTitle {
  font-size: 24px;
  font-weight: bold;
  display: block;
  text-align: center;
  color: #414141;
  margin: 22px 0;
}
.btns {
  text-align: center;
  margin: 50px 0 20px 0;
}

/* 上传图片 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  min-width: 70px;
  height: 78px;
  display: block;
}

/*  */
.image {
  width: 100px;
  height: 100px;
  margin-left: 20px;
  border-radius: 10px;
  position: relative;
}
.image img,
input {
  width: 170px;
  height: 170px;
  border-radius: 10px;
}
.image input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9999;
  /* // background-color #000 */
}
.agein {
  text-align: right;
}
.table-img {
  width: auto;
  height: 100px;
}
</style>
